@import '../../common/style/styleguide';

.of-custom-form {
  justify-content: center;
  margin: 4 * $global-baseline 0;

  .bottom-margin {
    margin-bottom: $global-baseline;
  }

  .top-margin {
    margin-top: $global-baseline;
  }

  .disclaimer-text {
    font-family: 'Open Sans', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4em;
  }

  .ea-input {
    width: 100%;
  }

  .title {
    margin-bottom: $global-baseline;
  }

  .ds-grid__row {
    padding: 2 * $global-baseline;
    box-sizing: border-box;
  }

  .ds-checkbox__label {
    width: 100%;
  }

  .of-custom-form-radio {
    > label {
      display: flex;
      width: 100%;

      > span:not(.ds-radio__icon) {
        flex: 1;
        text-align: right;
      }
    }
  }

  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3em;
    margin: 0;
    padding-bottom: 0.2em;
  }

  &__note-text {
    font-size: 13px;
    margin-bottom: 6px;
    margin-top: 6px;
    width: 100%;
  }

  &__prices {
    margin: (4 * $global-baseline) 0 (4 * $global-baseline) 0;

    &-title {
      margin-bottom: 2 * $global-baseline;
    }

    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-disclaimer {
      margin: 0;
      float: right;
    }
  }

  &__quantities {
    margin-bottom: (4 * $global-baseline);

    &-quantity {
      display: flex;
      justify-content: space-between;
      white-space: pre-line;
      width: 100%;

      div {
        margin-bottom: $global-baseline;
      }
    }

    .ds-quantity {
      flex-wrap: nowrap;
    }
  }

  &__radios {
    margin-bottom: (4 * $global-baseline);

    &-radio {
      .of-formik-radio {
        float: left;
      }
      &-children {
        float: right;
        padding: 5px 0 12px;
      }
      &-compensator {
        display: flex;
        width: 100%;
      }
    }
  }
}
