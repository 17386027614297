@import '../../common/style/styleguide';

.mbb-alternatives {
  margin: $ds-baseline * 6 $ds-baseline * 2 0 $ds-baseline;

  .mbb-alternatives-info {
    .infobox-container {
      grid-template-columns: 1fr; // One column on small screens
      grid-column-gap: $ds-baseline;
      @media only screen and (min-width: $breakpoint-s) {
        display: grid;
        grid-template-columns: 1fr 1fr; // Two columns of equal width
        gap: $ds-baseline;
      }

      &-item {
        background-color: $ds-color__neutral-200;
        padding: $ds-baseline * 4;
        margin-bottom: $ds-baseline;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (min-width: $breakpoint-s) {
          margin-bottom: 0;
        }

        .icon-container-liikkuva-wifi {
          display: grid;
          grid-template-columns: repeat(3, min-content);
          grid-template-rows: repeat(2, 1fr);
        }

        .icon-container-yritysdata {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
          grid-template-rows: repeat(2, 1fr);
          justify-content: start;
        }

        .icon-container-item {
          text-align: center;
          white-space: nowrap;
        }

        .link-button-container {
          align-self: flex-start; // Align the button container to the start of the cross axis
          margin-top: auto; // Push the button container to the bottom
        }

        > h3 {
          margin-block-end: $ds-baseline * 1;
        }
      }
    }
  }

  .mbb-feature-comparison {
    margin: $ds-baseline * 2 0 $ds-baseline * 8 0;
  }
}
